import React from "react";
import {Outlet} from "react-router-dom";
import {GameLogo} from "../components/GameLogo";

export default function Root() {
    return <div
        style={{
            margin: '0 auto',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            background: 'linear-gradient(to bottom, white 30%, mediumpurple)'
        }}
    >
        <div style={{flex: 0.15}}/>
        <GameLogo/>
        <div style={{flex: 0.15}}/>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 6,
            width: '90%',
            maxWidth: '500px',
            margin: "auto",
            backgroundColor: '#faf0fa',
            borderRadius: '20px',
            boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.5)'
        }}>
            <Outlet/>
        </div>
        <div style={{flex: 0.2}}/>
    </div>
}