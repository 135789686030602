import {ToolButton} from "./ToolButton";
import React from "react";

export function ResetButton(props: { onClick: () => void }) {
    return <div style={{display: "flex", alignItems: "center", justifyContent: "center", flex: 1}}>
        <ToolButton
            onClick={props.onClick}
            isActive
            style={{
                backgroundColor: "indianred"
            }}
        >
            RESET
        </ToolButton>
    </div>;
}