import {Key, KeyProps} from "./Key";
import React from "react";

interface DeleteKeyProps {
    onKeyPress: KeyProps['onKeyPress']
}

export function DeleteKey(props: DeleteKeyProps) {
    const {
        onKeyPress
    } = props;

    return <Key
        letter={'DEL'}
        onKeyPress={() => onKeyPress('')}
        width={1.5}
    >
        {"DEL"}
    </Key>
}