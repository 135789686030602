import React, {useEffect, useState} from "react";

interface DialogProps {
    header: string,
    body?: string,
    isHidden?: boolean,
    onConfirm?: () => void,
    onCancel?: () => void,
    confirmText?: string,
    cancelText?: string
}

export const Dialog = (props: React.PropsWithChildren<DialogProps>) => {
    const {
        header,
        body,
        isHidden = false,
        onConfirm,
        onCancel,
        confirmText = 'OK',
        cancelText = 'Cancel',
        children
    } = props;
    useEffect(() => {
        if (!isHidden) {
            // Gradually increase opacity when alert is shown
            const timeout = setTimeout(() => {
                setAlertOpacity(1);
            }, 10);
            return () => clearTimeout(timeout)
        } else {
            // Gradually decrease opacity when alert is hidden
            setAlertOpacity(0);
        }
    }, [isHidden]);

    const [alertOpacity, setAlertOpacity] = useState(0);

    return !isHidden ? (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 2,
            transition: 'opacity 0.3s ease-in-out',
            opacity: alertOpacity,
        }}>
            <div style={{
                backgroundColor: 'white',
                boxShadow: '0px 8px 8px rgba(0, 0, 0, 1)',
                padding: '20px',
                textAlign: 'center',
                border: '5px solid mediumpurple',
                borderRadius: '20px',
                transition: 'opacity 0.5s ease-in-out',
                opacity: alertOpacity,
            }}>
                <p style={{fontSize: '32px', fontWeight: 'bold'}}>{header}</p>
                {body && <span style={{fontSize: '16px', whiteSpace: 'pre-line'}}>{body}</span>}
                <div style={{display: 'flex', justifyContent: 'center', gap: '20%', marginTop: '10%'}}>
                    {onConfirm && <button style={{
                        backgroundColor: 'mediumpurple',
                        color: 'white',
                        border: 'none',
                        padding: '10px 20px',
                        cursor: 'pointer',
                        fontSize: '16px',
                        borderRadius: '5px',
                    }} onClick={onConfirm}>{confirmText}
                    </button>}
                    {onCancel && <button style={{
                        backgroundColor: 'white',
                        color: 'mediumpurple',
                        border: '1px solid gray',
                        padding: '10px 20px',
                        cursor: 'pointer',
                        fontSize: '16px',
                        borderRadius: '5px',
                    }} onClick={onCancel}>{cancelText}
                    </button>}
                    {props.children}
                </div>
            </div>
        </div>
    ) : null;
};