import {Constants} from "../../common/constants";
import React from "react";

export default function LetterLabel({value, isMultiplied = false, fontSize, color}:
                                        { value: number, isMultiplied?: boolean, fontSize?: string, color?: string }) {
    return (
        <>
            {(value > 0) &&
                <div style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    top: 0,
                    margin: 0,
                    color: value > 0 ? (color || (isMultiplied ? 'mediumpurple' : Constants.Game.VALUE_COLORS[value - 1])) : "black",
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: fontSize || '12px',
                    lineHeight: 1
                }}>
                    <span style={{position: 'absolute', top: '5%', right: '5%'}}>{value}</span>
                </div>
            }
        </>
    );
}