import React from "react";
import {ToolButton} from "./ToolButton";
import {ResetButton} from "./ResetButton";

export interface ClassicGameToolsProps {
    showLetterValue: boolean
    toggleShowLetterValue: () => void
    submitGame: () => void
    resetGrid: () => void
}

export default function ClassicGameTools(props: ClassicGameToolsProps) {
    const {
        showLetterValue,
        toggleShowLetterValue,
        submitGame,
        resetGrid,
    } = props;

    return <div style={{
        display: 'flex',
        height: '100%',
    }}>
        <ResetButton onClick={resetGrid}/>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, height: '100%'}}>
            <ToolButton
                onClick={toggleShowLetterValue}
                isActive={!showLetterValue}
            >
                TOTAL
            </ToolButton>
        </div>
        <div style={{flex: 2}}/>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1}}>
            <ToolButton
                onClick={(e) => {
                    e.preventDefault();
                    submitGame();
                }}
                isActive
                isStatic
                style={{
                    color: 'white',
                }}
            >
                SUBMIT
            </ToolButton>
        </div>
    </div>;
}