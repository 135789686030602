import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {
    createBrowserRouter,
    RouterProvider
} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import {Root, Classic} from './routes';
import GameModeMenu from "./components/GameModeMenu";
import Puzzle from "./routes/puzzle";
import Error from "./components/Error";

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root/>,
        errorElement: <Error/>,
        children: [
            {
                index: true,
                element: <GameModeMenu/>
            },
            {
                path: 'classic',
                element: <Classic/>
            },
            {
                path: 'puzzle',
                element: <Puzzle/>
            }
        ]
    },
]);

ReactDOM.render(
  <React.StrictMode>
      <RouterProvider router={router}/>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();