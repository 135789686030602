import React from "react";
import {Dialog} from "./index";
import ShareIcon from "../../assets/icons/share.png"

type ShareDialogProps = React.ComponentProps<typeof Dialog> & {
    shareText: string
}
export default function ShareDialog(props: ShareDialogProps) {
    return <Dialog {...props}>
        <button style={{
            backgroundColor: 'mediumblue',
            color: 'white',
            border: '0',
            paddingLeft: '10px',
            cursor: 'pointer',
            fontSize: '16px',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
            gap: '5px'
        }} onClick={async () => {
            if (navigator.share) {
                await navigator.share({text: props.shareText});
            } else {
                alert("Sorry, sharing on this device/browser isn't supported yet.")
            }
        }}>
            Share
            <img style={{width: '28px', filter: 'invert(1)'}} src={ShareIcon}/>
        </button>
    </Dialog>
}