import React, {useEffect, useState} from "react";
import {Constants, HighScore} from '../../common';
import "./styles.css"
import {formatTimeElapsed} from "../../utils/strings";

const DATE_FORMAT = new Intl.DateTimeFormat("en-US",
    {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: Constants.TimeZone});

export default function GameInfo(props: { date: Date, points?: number, highScore?: HighScore, startTime?: number, solveTime?: number, pauseTime?: number }) {
    const showPoints = props.points !== undefined;
    const date = DATE_FORMAT.format(props.date);
    const highScorePoints = props.highScore?.points || 0;
    const startTime = props.startTime;
    const solveTime = props.solveTime;
    const pauseTime = props.pauseTime;
    const [timeElapsed, setTimeElapsed] = useState<number | undefined>(() => {
        if (solveTime !== undefined) {
            return solveTime;
        }
        if (startTime !== undefined) {
            if (pauseTime !== undefined) {
                return pauseTime - startTime;
            }
            return Date.now() - startTime;
        }
        return undefined;
    });
    useEffect(() => {
        if (startTime !== undefined && solveTime === undefined) {
            const interval = setInterval(() => {
                setTimeElapsed(prevState => {
                    if (startTime !== undefined) {
                        if (solveTime === undefined) {
                            return pauseTime === undefined ? Date.now() - startTime : pauseTime - startTime
                        } else {
                            clearInterval(interval);
                            return solveTime
                        }
                    }
                    return prevState;
                });
            }, 1000);
            return () => clearInterval(interval); // Clean up the interval on component unmount
        }
    }, [solveTime, startTime, pauseTime]);
    return <div style={{
        flex: 0.2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: '16px',
        lineHeight: '18px',
        marginBottom: '8px',
        width: '90%',
        maxWidth: '40vh',
        overflowX: 'auto',
        overflowY: 'hidden',
        whiteSpace: 'nowrap',
    }}
    >
        <div style={{
            display: 'flex',
            fontSize: '20px',
            flex: 1,
            width: '100%',
            textAlign: 'center',
            alignItems: 'center',
            marginTop: '5px'
        }}>
            <span style={{flex: 1}}>{date}</span>
        </div>
        {showPoints && <div style={{
            position: 'relative',
            flex: 1,
            display: 'flex',
            width: '100%',
        }}>
            <div style={{
                fontWeight: 'bold',
                flex: 0.4,
                display: 'flex',
                alignItems: 'center',
            }}>
                <span style={{flex: 1}}>{`Points: ${props.points}`}</span>
            </div>
            <div className={"high-score"} style={{
                textAlign: 'right',
                flex: 0.6,
                display: 'flex',
                alignItems: 'center',
            }}>
                <span style={{flex: 1}}>{`High Score: ${highScorePoints}`}&nbsp;</span>
                {highScorePoints ? (<span>{`(by `}
                        <span style={{fontStyle: props.highScore?.user ? 'normal' : 'italic'}}>
                            {`${props.highScore?.user || 'anonymous'})`}
                        </span>
                    </span>
                ) : null}
            </div>
        </div>}
        {timeElapsed !== undefined && <div>
            <span style={{flex: 1}}>{`Timer: ${formatTimeElapsed(timeElapsed)}`}</span>
        </div>}
    </div>;
}