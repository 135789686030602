import React, {useCallback, useEffect} from 'react';
import {Direction, Letter, MultiplierTile, StaticTile, Tile} from "../../common/index";
import LetterLabel from "../LetterLabel/LetterLabel";
import {MultiplierLetterLabel} from "../LetterLabel/MultiplierLetterLabel";
import './styles.css';

interface GameTileProps {
    tile: Tile
    letter: Letter,
    letterValue: number,
    tileValue: number,
    hasFocus: boolean
    setFocus: () => void
    onClick: () => void
    gridDimension: number
    focusDirection?: Direction.RIGHT | Direction.DOWN
    showLetterValue: boolean
    colour: string
    animate: boolean
    hideChar?: boolean
}

function FocusDirectionArrow({focusDirection}: {focusDirection: GameTileProps['focusDirection']}) {
    const rotation = focusDirection === Direction.RIGHT ? '90deg' : '180deg';
    const style: React.CSSProperties = {
        position: 'absolute',
        zIndex: 1,
        border: 0,
        width: 0,
        height: 0,
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
        borderBottom: '10px solid mediumpurple',
        transform: `rotate(${rotation})`
    }
    if (focusDirection === Direction.RIGHT) {
        style.top = 'calc(50% - 5px)'
        style.left = '100%'
    } else {
        style.top = '100%'
        style.left = 'calc(50% - 5px)'
    }
    return (
        <div style={style}/>
    )
}

export default function GameTile(props: GameTileProps) {
    const {
        tile,
        letter = {char: '', isValid: false, numMemberships: 0},
        letterValue = 0,
        tileValue = 0,
        hasFocus,
        setFocus,
        onClick,
        focusDirection,
        showLetterValue,
        colour,
        animate,
        hideChar = false
    } = props;

    const btnRef = React.useRef<HTMLButtonElement | null>(null);
    //const [fontSize, setFontSize] = useState<number>(Math.min(btnRef?.current?.offsetWidth || 0, btnRef?.current?.offsetHeight || 0));

    // useEffect(() => {
    //     setFontSize(
    //         Math.min(btnRef?.current?.offsetWidth || 0, btnRef?.current?.offsetHeight || 0)
    //     );
    // }, [gridDimension]);

    useEffect(() => {
        if (hasFocus && document.activeElement !== btnRef.current) {
            btnRef.current?.focus();
        }
    }, [btnRef, hasFocus]);

    const onFocus = useCallback(() => {
        if (!hasFocus && !(tile instanceof StaticTile)) {
            setFocus();
        }
    }, [hasFocus, setFocus, tile]);

    const hasMultiplier = tile instanceof MultiplierTile;
    const tileLetterClassNames = animate ? 'animation-jump' : '';
    return (
        <div className={"square"}>
            <div className={"content"}>
                <button
                    ref={btnRef}
                    onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault();
                        if (hasFocus) {
                            onClick();
                        } else {
                            btnRef.current?.focus();
                        }
                    }}
                    onFocus={onFocus}
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: colour,
                        margin: 0,
                        outline: "none",
                        border: (() => {
                            const baseDim = 2;
                            const highlightDim = baseDim * 1.5;
                            const focusDim = highlightDim * 2;
                            if (hasFocus) {
                                return `${focusDim}px solid mediumpurple`;
                            } else {
                                if (letter.char !== '') {
                                    if (letter.numMemberships > 0) {
                                        return `${highlightDim}px solid green`;
                                    } else if (letter.isValid !== undefined) {
                                        return `${highlightDim}px solid red`;
                                    }
                                }
                            }
                            return 0;
                        })(),
                        padding: 0,
                        userSelect: 'none',
                        WebkitTouchCallout: 'none', /* iOS Safari */
                        WebkitUserSelect: 'none', /* Safari */
                        KhtmlUserSelect: 'none', /* Konqueror HTML */
                        MozUserSelect: 'none', /* Old versions of Firefox */
                        msUserSelect: 'none',
                        color: 'black'
                    }}
                >
                    {tile &&
                        <>
                            <div
                                className={tileLetterClassNames}
                                style={{
                                    //fontSize: `${Math.floor(fontSize * 0.4)}px`,
                                    fontSize: '28px',
                                    lineHeight: 1,
                                    fontWeight: 'bold',
                                    color: 'black'
                                }}
                            >
                                {hideChar ? " " : letter.char.toUpperCase()}
                            </div>
                            <LetterLabel
                                value={showLetterValue ? letterValue : tileValue}
                                isMultiplied={hasMultiplier}
                                fontSize={'14px'}
                                color={showLetterValue ? undefined : 'blue'}
                            />
                            {hasMultiplier && <MultiplierLetterLabel multiplier={tile.multiplier} fontSize={'14px'}/>}
                        </>
                    }
                </button>
                {(hasFocus && focusDirection) ? <FocusDirectionArrow focusDirection={focusDirection}/> : null}
            </div>
        </div>
    );
}