import React from "react";

export function MultiplierLetterLabel(props: { multiplier: number, fontSize?: string }) {
    return <div style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        fontWeight: "bold",
        top: "0%",
        margin: 0,
        color: "mediumpurple",
        justifyContent: "center",
        alignItems: "center",
        fontSize: props.fontSize || '16px',
        lineHeight: 1
    }}>
        <span style={{position: 'absolute', bottom: '2%', left: '2%'}}>{`x${props.multiplier}`}</span>
    </div>;
}