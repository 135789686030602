import React from "react";
import {ToolButton} from "./ToolButton";
import {ResetButton} from "./ResetButton";

export interface PuzzleGameToolsProps {
    resetGrid: () => void
    isTimerPaused: boolean
    togglePauseTimer: () => void
}

export default function PuzzleGameTools(props: PuzzleGameToolsProps) {
    const {
        resetGrid,
        isTimerPaused,
        togglePauseTimer
    } = props;

    return <div style={{
        display: 'flex',
        height: '100%',
    }}>
        <ResetButton onClick={resetGrid}/>
        <div style={{flex: 3}}/>
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", flex: 1}}>
            {!isTimerPaused && <ToolButton
                onClick={togglePauseTimer}
                isActive={!isTimerPaused}
            >
                {"PAUSE"}
            </ToolButton>}
        </div>
    </div>;
}