import {Constants} from "../common/constants";
import {GridState, GridStateAction} from "../types/grid";
import {Direction, EditableTile, findWords, StaticTile, Tile} from "../common/utils";
import React, {useEffect, useReducer} from "react";
import {getLocalStoragePath} from "../utils/localStorage";
import {lessThanOneDayElapsed} from "../utils/dates";

interface GridStateReducerProps {
    validWordLengths?: number[],
    localStorage: Storage,
    localStorageSubPath: string,
}

export function useGridStateReducer(props: GridStateReducerProps): [GridState, React.Dispatch<GridStateAction>] {
    const {
        validWordLengths,
        localStorage,
        localStorageSubPath
    } = props;
    const isValidSavedGameState = (obj: unknown): boolean => {
        return Array.isArray(obj) && obj.length === Constants.Grid.size();
    }

    const getLocalStorageItem = (key: string) => localStorage.getItem(getLocalStoragePath(localStorageSubPath, key))
    const setLocalStorageItem = (key: string, value: string) => localStorage.setItem(getLocalStoragePath(localStorageSubPath, key), value)
    const clearLocalStorage = () => {
        const keysToRemove = []
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i) || '';
            if (key.startsWith(localStorageSubPath)) {
                keysToRemove.push(key)
            }
        }
        for (const key of keysToRemove) {
            localStorage.removeItem(key)
        }
    }
    const gridStateReducer = (prevState: GridState, action: GridStateAction): GridState => {
        switch (action.type) {
            case 'initGrid': {
                let hasValidInitialState = false;
                const startingTiles = action.payload.startingTiles;
                const payloadLetters = action.payload.letters;
                if (!payloadLetters && prevState.letters.length === Constants.Grid.size()) {
                    hasValidInitialState = true;
                    // Check if pre-initialized letters have letters from current game's static tiles
                    for (const startingTile of startingTiles) {
                        if (!startingTile.editable) {
                            const i = startingTile.id;
                            if (prevState.letters[i]?.char !== startingTile.startingChar) {
                                hasValidInitialState = false;
                                break;
                            }
                        }
                    }
                }
                const instantiatedTiles = startingTiles.map(tile => Tile.fromPlainObject(tile));
                const tiles = [];
                for (const tile of instantiatedTiles) {
                    tiles[tile.id] = tile;
                }
                for (let i = 0; i < Constants.Grid.size(); i++) {
                    if (!tiles[i]) {
                        tiles[i] = new EditableTile(i);
                    }
                }
                const newLetters = tiles.map(tile =>
                    tile.getLetter(payloadLetters ? payloadLetters[tile.id]?.char : prevState.letters[tile.id]?.char));
                startingTiles.forEach((tile) => {
                    if (tile.startingChar) {
                        newLetters[tile.id] = {
                            ...newLetters[tile.id],
                            char: tile.startingChar
                        };
                    }
                });
                if (!hasValidInitialState) {
                    setLocalStorageItem('gridState', JSON.stringify(newLetters.map(letter => letter.char)));
                }
                const date = action.payload.date
                const newState = {
                    ...prevState,
                    tiles: tiles,
                    letters: hasValidInitialState ? prevState.letters : newLetters,
                    date: date || prevState.date,
                    validWords: action.payload.validWords
                };
                if (date) {
                    setLocalStorageItem('lastPlayedDate', JSON.stringify(date));
                }
                return gridStateReducer(newState, {type: 'updateWords'});
            }
            case 'keyDown': {
                if (prevState.focusedTile !== undefined) {
                    const key = action.payload.key
                    let char;
                    if (key === 'Backspace' || key === 'Delete') {
                        char = ''
                    } else if (Constants.Game.ALPHABET.includes(key)) {
                        char = key
                    }
                    if (char !== undefined) {
                        return gridStateReducer(prevState, {
                            type: 'typeChar', payload: {char: char}
                        });
                    }
                }
                return prevState;
            }
            case 'typeChar': {
                return gridStateReducer(gridStateReducer(prevState, {
                    type: 'setChar',
                    payload: {char: action.payload.char}
                }), {type: 'moveFocus'});
            }
            case 'setChar': {
                const targetTileId = action.payload.tileId !== undefined ? action.payload.tileId : prevState.focusedTile;
                if (targetTileId !== undefined) {
                    let newState = prevState;
                    const prevLetter = prevState.letters[targetTileId]
                    if (prevLetter.char !== action.payload.char) {
                        const newLetters = prevState.letters.map((letter) => ({...letter}));
                        newLetters[targetTileId] = {
                            char: action.payload.char,
                            numMemberships: 0,
                            isValid: false,
                        }
                        newState = {
                            ...prevState,
                            letters: newLetters
                        };
                        newState = gridStateReducer(gridStateReducer(newState, {type: 'updateWords'}), {
                            type: 'setTileAnimation',
                            payload: {'tileIds': [targetTileId], status: true}
                        });
                        // Save new state to local storage
                        setLocalStorageItem('gridState', JSON.stringify(newState.letters.map(letter => letter.char)));
                    }
                    return newState;
                }
                return prevState;
            }
            case
            'focus'
            : {
                const newFocusedTile = action.payload.focusedTile;
                if (prevState.focusedTile !== newFocusedTile) {
                    return {
                        ...prevState,
                        focusedTile: newFocusedTile
                    }
                } else {
                    return prevState;
                }
            }
            case
            'moveFocus'
            : {
                if (prevState.focusedTile === undefined) {
                    return prevState;
                }
                let currId = prevState.focusedTile;
                const focusDirection = prevState.focusDirection;
                const reverseFocus = prevState.letters[prevState.focusedTile].char === '';
                do {
                    const nextTileId = Tile.nextIdInDirection(currId, focusDirection, reverseFocus);
                    // Check that next is not an end tile in row/col
                    if (nextTileId !== undefined) {
                        currId = nextTileId
                    }
                    // Next tile is static and last in direction
                    if (Tile.isLastInDirection(currId, focusDirection, reverseFocus) &&
                        prevState.tiles[currId] instanceof StaticTile) {
                        // Cancel nextFocus
                        return prevState;
                    }
                } while (prevState.tiles[currId] instanceof StaticTile);
                return {
                    ...prevState,
                    focusedTile: currId
                };
            }
            case
            'toggleFocusDirection'
            : {
                return {
                    ...prevState,
                    focusDirection: prevState.focusDirection === Direction.RIGHT ? Direction.DOWN : Direction.RIGHT
                }
            }
            case
            'updateWords'
            : {
                const newLetters = prevState.letters.map((letter, i) => ({
                    ...letter,
                    // Static tiles have isValid == undefined by default, all others have false
                    isValid: prevState.tiles[i] instanceof StaticTile ? undefined : false,
                    numMemberships: 0
                }));
                const tiles = prevState.tiles;
                const chars = prevState.letters.map((letter) => letter.char);
                const {foundValidWords, foundInvalidWords} = findWords(chars, tiles, prevState.validWords);
                // Validate word tiles
                for (const group of foundValidWords) {
                    if (validWordLengths === undefined || validWordLengths.includes(group.group.length)) {
                        for (const id of group.memberTileIds) {
                            newLetters[id].isValid = true;
                            newLetters[id].numMemberships += 1;
                        }
                    } else {
                        for (const id of group.memberTileIds) {
                            newLetters[id].isValid = false;
                        }
                    }
                }
                // Invalidate non-word tiles
                for (const group of foundInvalidWords) {
                    for (const id of group.memberTileIds) {
                        newLetters[id].isValid = false;
                    }
                }
                let validLetterIds: number[] = [];
                // Check if all word tiles are still valid
                for (const group of foundValidWords) {
                    if (group.memberTileIds.every((id) => newLetters[id].isValid)) {
                        for (const id of group.memberTileIds) {
                            validLetterIds.push(id);
                        }
                    }
                }
                return {
                    ...prevState,
                    letters: newLetters,
                    validLetterIds,
                    invalid: foundInvalidWords.length > 0
                }
            }
            case
            'updatePoints'
            : {
                let newPoints = 0;
                for (const id of prevState.validLetterIds) {
                    const letter = prevState.letters[id];
                    const value = action.payload.values.get(letter.char) || 0;
                    newPoints += prevState.tiles[id].getValue(value);
                }
                return {
                    ...prevState,
                    points: newPoints
                };
            }
            case
            'clearLocalStorage'
            : {
                clearLocalStorage();
                return prevState;
            }
            case
            'setTileAnimation'
            : {
                const tilesAnimation = [...prevState.tilesAnimation]
                for (const tileId of action.payload.tileIds) {
                    tilesAnimation[tileId] = action.payload.status;
                }
                return {
                    ...prevState,
                    tilesAnimation
                }
            }
        }
    }

    function isValidSavedState(lastPlayedDate: Date | null, savedGridState: unknown) {
        return lastPlayedDate && lessThanOneDayElapsed(new Date(lastPlayedDate)) && isValidSavedGameState(savedGridState);
    }

    const initGridState = () => {
        const today = new Date();
        const defaultInitialState: GridState = {
            tiles: [],
            letters: [],
            points: 0,
            validLetterIds: [],
            date: today,
            invalid: false,
            showLetterValue: true,
            focusDirection: Direction.RIGHT,
            focusedTile: undefined,
            tilesAnimation: Array.from({length: Constants.Grid.size()}, () => false)
        };
        const rawLastPlayedDate = getLocalStorageItem('lastPlayedDate');
        const lastPlayedDate: Date = JSON.parse(rawLastPlayedDate || 'null');
        const rawGridState = getLocalStorageItem('gridState');
        const savedGridState: string[] = JSON.parse(rawGridState || 'null');
        if (isValidSavedState(lastPlayedDate, savedGridState)) {
            return {
                ...defaultInitialState,
                letters: savedGridState.map((char) => ({char, numMemberships: 0}))
            }
        }
        clearLocalStorage();
        setLocalStorageItem('gridState', JSON.stringify(Array(Constants.Grid.size()).fill('')));
        return defaultInitialState;
    }

    const [state, dispatch] = useReducer(gridStateReducer, undefined, initGridState);


    useEffect(() => {
        const tileIdsToDisable: number[] = [];
        for (const [tileId, status] of state.tilesAnimation.entries()) {
            if (status) {
                tileIdsToDisable.push(tileId);
            }
        }
        if (tileIdsToDisable.length > 0) {
            const timeout = setTimeout(() => {
                dispatch({
                    type: 'setTileAnimation',
                    payload: {
                        tileIds: tileIdsToDisable,
                        status: false
                    }
                })
            }, 250);
            return () => clearTimeout(timeout)
        }
    }, [state.tilesAnimation]);
    return [state, dispatch]
}