import WORDS from './words.json';
import PUZZLE_WORDS from './puzzle_words.json';

const Constants = Object.freeze({
    Grid: {
        WIDTH: 5,
        HEIGHT: 5,
        size: () => Constants.Grid.WIDTH * Constants.Grid.HEIGHT
    },
    Game: {
        ALPHABET: "abcdefghijklmnopqrstuvwxyz",
        NUM_STARTING_LETTERS: 2,
        NUM_MULTIPLIER_TILES: 1,
        MULTIPLIER_VALUES: [2],
        MAX_LETTER_POINT: 6,
        VALUE_COLORS: ['#FF0D0D', '#FF4E11', '#FF8E15', '#FAB733', '#ACB334', '#69B34C'],
        MIN_WORD_LEN: 2
    },
    Player: {
        USER_MAX_LEN: 12
    },
    TimeZone: process.env.TZ || "America/Toronto"
});

export {
    WORDS,
    PUZZLE_WORDS,
    Constants
}