import React from "react";

interface ToolButtonProps {
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
    isStatic?: boolean
    isActive?: boolean
    children?: React.ReactNode
    style?: React.CSSProperties
}

export function ToolButton(props: ToolButtonProps) {
    const {
        onClick,
        isStatic = false,
        isActive = false,
        children,
        style,
    } = props;
    return (
        <button
            onClick={onClick}
            style={{
                fontSize: `12px`,
                backgroundColor: isActive ? 'mediumpurple' : 'gray',
                border: isStatic ? '1px solid black' : 0,
                padding: 0,
                borderRadius: '4px',
                width: '95%',
                height: '100%',
                color: 'white',
                boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.8)',
                ...style
            }}
        >
            {children}
        </button>
    );
}