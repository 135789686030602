import logo from "../../assets/logo.png";
import React from "react";
import {Link} from "react-router-dom";

export function GameLogo() {
    return <div
        style={{
            position: "relative",
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "48px",
            height: "100%",
            width: "90%",
            margin: "auto",
            objectFit: "contain",
            imageRendering: "auto",
            flex: 0.5,
            overflow: "hidden",
            textDecoration: 'none'
        }}>
        <Link to={'/'} style={{position: 'absolute', width: '100%', height: '100%', zIndex: 100}}/>
        <img src={logo}
                    style={{
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        objectFit: "contain",
                        imageRendering: "auto",
                        overflow: "hidden", textDecoration: 'none'
                    }}
                    alt={"squary"}
    />
    </div>
}