import React from "react";
import './GameModeMenu.css';
import {Link} from "react-router-dom";

export default function GameModeMenu() {
    return <div style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center"
    }}>
        <div className={"game-mode-selector"}>
            <Link to={"classic"} className={"game-mode-selector__btn game-mode-selector__btn--classic"}>
                <header style={{letterSpacing: '4px'}}>CLASSIC</header>
                <div
                    className={"game-mode-menu__description"}>{"The original scrabble-like, daily word game.\nTry to get today's highest score."}</div>
            </Link>
        </div>
        <div id={"game-mode-menu__prompt"}>Pick a mode!</div>
        <div className={"game-mode-selector"}>
            <Link to={"puzzle"} className={"game-mode-selector__btn game-mode-selector__btn--puzzle"}>
                <div style={{letterSpacing: '4px'}}>PUZZLE</div>
                <span
                    className={"game-mode-menu__description"}>{"Still SQUARY, but with a puzzling twist.\nSolve a new puzzle each day."}</span>
            </Link>
        </div>
    </div>;
}