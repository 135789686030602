import {GridState, GridStateAction, PuzzleGridState} from "../types/grid";
import {getLocalStoragePath} from "../utils/localStorage";
import {useGridStateReducer} from "./useGridStateReducer";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Constants, Tile, ITile, Letter} from "../common"


interface InternalPuzzleGridState {
    isSolved: boolean,
    charsRemaining: Map<string, number>,
    solutionChars: string[],
    newGridAction?: GridStateAction,
    newGridActionChar?: string,
    newGridActionTileId?: number,
    startTime?: number,
    solveTime?: number,
    pauseTime?: number
    solveOrder: number[],
    // originalTile?: number,
    // targetTile?: number,
    // targetTileFilled?: boolean
}

type PuzzleGridStateAction =
    Exclude<GridStateAction, { type: 'initGrid', payload: { startingTiles: ITile[], letters?: Letter[], date?: Date, validWords?: Set<string> } }>
    | { type: 'initGrid', payload: { startingTiles: ITile[], letters?: Letter[], date?: Date, validWords?: Set<string>, startingCharsRemaining: Map<string, number>, solutionChars: string[] } }
    | { type: 'startPuzzle' }
    | { type: 'pausePuzzle' }
    | { type: 'resumePuzzle' }


const localStorage = window.localStorage;
const localStorageSubPath = 'puzzle/v1';

const getLocalStorageItem = (key: string) => localStorage.getItem(getLocalStoragePath(localStorageSubPath, key));

function parseLocalStorageNum(key: string, dependencies: string[] = []) {
    let val = Number(getLocalStorageItem(key)) || undefined;
    if (val === undefined || isNaN(val)) {
        localStorage.removeItem(getLocalStoragePath(localStorageSubPath, key));
        for (let dep of dependencies) {
            localStorage.removeItem(getLocalStoragePath(localStorageSubPath, dep));
        }
        val = undefined;
    }
    return val
}


export default function usePuzzleGridStateReducer(): [PuzzleGridState, React.Dispatch<PuzzleGridStateAction>] {

    const [gridState, dispatchGridState] = useGridStateReducer({
        validWordLengths: [5],
        localStorage,
        localStorageSubPath,
    });
    const gridStateRef = useRef<GridState>(gridState);
    gridStateRef.current = gridState;

    const [puzzleState, setPuzzleState] = useState<InternalPuzzleGridState>(() => {
        // const startTime = parseLocalStorageNum('startTime');
        // const solveTime = parseLocalStorageNum('solveTime');
        // let solveOrder = JSON.parse(getLocalStorageItem('solveOrder') || '[]');
        // if (!Array.isArray(solveOrder)) {
        //     solveOrder = [];
        // }
        // solveOrder = solveOrder.map((num: unknown) => Number(num))
        // for (const num of solveOrder) {
        //     if (!Number.isInteger(num)) {
        //         solveOrder = [];
        //         break;
        //     }
        // }
        return {
            isSolved: false,
            charsRemaining: new Map<string, number>(),
            solutionChars: [],
            // timeElapsed: solveTime !== undefined ? solveTime : startTime && Date.now() - startTime,
            // solveTime: solveTime,
            // solveOrder: solveOrder
            solveOrder: []
        }
    });
    useEffect(() => {
        const action = puzzleState.newGridAction;
        const char = puzzleState.newGridActionChar;
        const targetTileId = puzzleState.newGridActionTileId;
        if (action !== undefined) {
            if (targetTileId === undefined || char === undefined) {
                setPuzzleState(prevState => ({
                    ...prevState,
                    newGridAction: undefined,
                    newGridActionChar: undefined,
                    newGridActionTileId: undefined
                }));
            } else {
                const mirrorTileId = Tile.getMirrorTile(targetTileId);
                const mirrorTile = gridStateRef.current.tiles[mirrorTileId];
                if (mirrorTileId !== targetTileId && mirrorTile.editable) dispatchGridState({
                    type: 'setChar',
                    payload: {char: char, tileId: mirrorTileId}
                });
            }
            dispatchGridState(action);
        }
    }, [dispatchGridState, puzzleState.newGridAction, puzzleState.newGridActionChar, puzzleState.newGridActionTileId]);
    useEffect(() => {
        const targetTile = puzzleState.newGridActionTileId;
        if (targetTile !== undefined && gridState.letters[targetTile].char === puzzleState.newGridActionChar) {
            setPuzzleState(prevState => ({
                ...prevState,
                newGridAction: undefined,
                newGridActionChar: undefined,
                newGridActionTileId: undefined
            }));
        }
    }, [gridState.letters, puzzleState.newGridActionChar, puzzleState.newGridActionTileId]);
    useEffect(() => {
        setPuzzleState(prevState => {
            if (prevState.startTime === undefined) {
                return prevState;
            }
            let newState = {...prevState}
            let isSolved = true;
            for (const letter of gridState.letters) {
                if (!letter.isValid) {
                    isSolved = false;
                }
            }
            if (isSolved && prevState.solveTime === undefined) {
                const solveTime = Date.now() - prevState.startTime;
                localStorage.setItem(getLocalStoragePath(localStorageSubPath, 'solveTime'), JSON.stringify(solveTime));
                newState.solveTime = solveTime;
            }
            newState.isSolved = isSolved;
            let newSolveOrderIdx = undefined
            for (let i = 0; i < Constants.Grid.HEIGHT; i++) {
                if (prevState.solveOrder.includes(i)) {
                    continue
                }
                if (gridState.letters[i * Constants.Grid.WIDTH + i]?.isValid) {
                    newSolveOrderIdx = i;
                    break;
                }
            }
            if (newSolveOrderIdx !== undefined) {
                const newSolveOrder = [...prevState.solveOrder, newSolveOrderIdx]
                localStorage.setItem(getLocalStoragePath(localStorageSubPath, 'solveOrder'), JSON.stringify(newSolveOrder));
                newState.solveOrder = newSolveOrder;
            }
            return newState;
        })
    }, [gridState.letters])

    const dispatch = useCallback((action: PuzzleGridStateAction) => {
        switch (action.type) {
            case "initGrid":
                const charsRemainingWithoutStartingChars = new Map(action.payload.startingCharsRemaining);
                const startingTileIds = new Set<number>();
                action.payload.startingTiles.forEach((tile) => {
                    startingTileIds.add(tile.id);
                    const char = tile.startingChar;
                    if (char) {
                        const remaining = charsRemainingWithoutStartingChars.get(char) || 0;
                        charsRemainingWithoutStartingChars.set(char, remaining - 1);
                    }
                });
                const currentCharsRemaining = new Map(charsRemainingWithoutStartingChars);
                const solutionChars = action.payload.solutionChars;
                const validWords = new Set<string>();
                for (let i = 0; i < Constants.Grid.HEIGHT; i++) {
                    const start = i * Constants.Grid.WIDTH;
                    const end = start + Constants.Grid.WIDTH;
                    validWords.add(solutionChars.slice(start, end).join(''));
                    const boundaryTileId = start + i;
                    if (!startingTileIds.has(boundaryTileId)) {
                        action.payload.startingTiles.push({
                            id: start + i,
                            editable: true,
                            colour: 'thistle'
                        });
                    }
                }
                action.payload.validWords = validWords;
                let invalidGridState = false;
                for (let i = 0; i < Constants.Grid.size(); i++) {
                    if (startingTileIds.has(i)) {
                        continue;
                    }
                    const char = gridStateRef.current.letters[i]?.char;
                    const remaining = currentCharsRemaining.get(char) || 0;
                    if (char) {
                        if (remaining) {
                            currentCharsRemaining.set(char, remaining - 1);
                        } else {
                            invalidGridState = true;
                            break;
                        }
                    }
                }
                if (invalidGridState) {
                    dispatchGridState({type: 'initGrid', payload: {...action.payload, letters: []}});
                } else {
                    dispatchGridState(action);
                }
                const startTime = parseLocalStorageNum('startTime', ['solveTime', 'pauseTime', 'solveOrder']);
                const solveTime = parseLocalStorageNum('solveTime');
                const pauseTime = parseLocalStorageNum('pauseTime');
                let solveOrder = JSON.parse(getLocalStorageItem('solveOrder') || '[]');
                if (!Array.isArray(solveOrder)) {
                    solveOrder = [];
                }
                solveOrder = solveOrder.map((num: unknown) => Number(num))
                for (const num of solveOrder) {
                    if (!Number.isInteger(num)) {
                        solveOrder = [];
                        break;
                    }
                }
                setPuzzleState(prevState => ({
                    ...prevState,
                    solveTime,
                    startTime,
                    pauseTime,
                    solveOrder,
                    solutionChars,
                    charsRemaining: invalidGridState ? charsRemainingWithoutStartingChars : currentCharsRemaining,
                }));
                break;
            case "keyDown":
            case "typeChar":
                let newChar: string | undefined = undefined;
                const currGridState = gridStateRef.current;
                if (action.type === 'keyDown') {
                    const key = action.payload.key;
                    if (key === 'Backspace' || key === 'Delete') {
                        newChar = '';
                    } else if (Constants.Game.ALPHABET.includes(key)) {
                        newChar = key;
                    }
                } else if (action.type === 'typeChar') {
                    const payload = action.payload.char;
                    if (Constants.Game.ALPHABET.includes(payload)) {
                        newChar = payload
                    }
                }
                const currentTileId = currGridState.focusedTile;
                if (currentTileId !== undefined && currGridState.tiles[currentTileId].editable && newChar !== undefined) {
                    const prevChar = currGridState.letters[currentTileId].char;
                    let isSameChar = false;
                    if (newChar === prevChar) {
                        isSameChar = true;
                    }
                    setPuzzleState(prevState => {
                        const {charsRemaining, newGridAction} = prevState;
                        if (newGridAction) {
                            return prevState;
                        }
                        let newCharsRemaining = charsRemaining;
                        if (!isSameChar && newChar !== undefined) {
                            newCharsRemaining = new Map(charsRemaining);
                            const mirrorTileId = Tile.getMirrorTile(currentTileId);
                            const mirrorTile = currGridState.tiles[mirrorTileId];
                            const prevMirrorChar = currGridState.letters[mirrorTileId].char;
                            if (newChar !== '') {
                                let newCharRemaining = (newCharsRemaining.get(newChar) || 0) - 1;
                                if (mirrorTileId !== currentTileId && mirrorTile.editable) newCharRemaining--;
                                if (newCharRemaining < 0) {
                                    return {
                                        ...prevState,
                                        charsRemaining: charsRemaining,
                                        newGridAction: undefined,
                                        newGridActionChar: undefined,
                                        newGridActionTileId: undefined
                                    };
                                } else {
                                    newCharsRemaining.set(newChar, newCharRemaining);
                                }
                            }
                            if (prevChar !== '') {
                                const prevCharRemaining = newCharsRemaining.get(prevChar) || 0;
                                newCharsRemaining.set(prevChar, prevCharRemaining + 1);
                            }
                            if (mirrorTileId !== currentTileId && mirrorTile.editable && prevMirrorChar !== '') {
                                const prevCharRemaining = newCharsRemaining.get(prevMirrorChar) || 0;
                                newCharsRemaining.set(prevMirrorChar, prevCharRemaining + 1);
                            }
                        }
                        return {
                            ...prevState,
                            charsRemaining: newCharsRemaining,
                            newGridAction: action,
                            newGridActionChar: newChar,
                            newGridActionTileId: gridStateRef.current.focusedTile
                        };
                    });
                    return;
                }
                dispatchGridState(action);
                break;
            case 'startPuzzle':
                const start = Date.now()
                localStorage.setItem(getLocalStoragePath(localStorageSubPath, 'startTime'), JSON.stringify(start));
                setPuzzleState(prevState => ({...prevState, startTime: start}));
                break;
            case 'pausePuzzle':
                const pause = Date.now()
                localStorage.setItem(getLocalStoragePath(localStorageSubPath, 'pauseTime'), JSON.stringify(pause));
                setPuzzleState(prevState => ({...prevState, pauseTime: pause}));
                break;
            case 'resumePuzzle':
                localStorage.removeItem(getLocalStoragePath(localStorageSubPath, 'pauseTime'))
                setPuzzleState(prevState => {
                    if (prevState.startTime !== undefined && prevState.pauseTime !== undefined) {
                        const updatedStart = Date.now() - (prevState.pauseTime - prevState.startTime);
                        localStorage.setItem(getLocalStoragePath(localStorageSubPath, 'startTime'), JSON.stringify(updatedStart));
                        return {
                            ...prevState,
                            pauseTime: undefined,
                            startTime: Date.now() - (prevState.pauseTime - prevState.startTime)
                        }
                    }
                    return prevState;
                });
                break;
            default:
                dispatchGridState(action);
                break;
        }
    }, [dispatchGridState]);
    const state = useMemo(() => ({
        ...gridState,
        charsRemaining: puzzleState.charsRemaining,
        isSolved: puzzleState.isSolved,
        startTime: puzzleState.startTime,
        pauseTime: puzzleState.pauseTime,
        solveTime: puzzleState.solveTime,
        solveOrder: puzzleState.solveOrder
    }), [gridState, puzzleState.charsRemaining, puzzleState.isSolved, puzzleState.pauseTime, puzzleState.solveOrder, puzzleState.solveTime, puzzleState.startTime])
    return [state, dispatch]
}