// fetch polyfill
import 'whatwg-fetch';
import {Game, GameSolutionResponse, GameSolution, PuzzleGame} from "../common";

export function getCurrentClassicGame(): Promise<Game> {
    const uri = '/api/game/classic'
    return window.fetch(uri)
        .then((res) => {
            if (!res.ok) {
                return res.json()
                    .then(({error}: {error: string}) => {
                        return Promise.reject(error || 'Could not get current game');
                    });
            } else {
                return res.json();
            }
        })
        .then((game: Game) => {
            const date = new Date(game.date);
            const tiles = game.tiles;
            const charValues = new Map<string, number>(Object.entries(game.charValues));
            const highScore = game.highScore;
            return {
                date,
                tiles,
                charValues,
                highScore
            }
        })
}

export function submitSolution(solution: GameSolution): Promise<GameSolutionResponse> {
    const uri = '/api/game/classic/solution';
    return window.fetch(uri, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(solution)
        })
        .then((res) => {
            if (!res.ok) {
                return res.json()
                    .then(({error}: GameSolutionResponse) => {
                        return Promise.reject(error || 'Could not submit solution');
                    });
            } else {
                return res.json();
            }
        })
        .then((res: GameSolutionResponse) => {
            return res;
        })
}

export function getCurrentPuzzleGame(): Promise<PuzzleGame> {
    const uri = '/api/game/puzzle'
    return window.fetch(uri)
        .then((res) => {
            if (!res.ok) {
                return res.json()
                    .then(({error}: {error: string}) => {
                        return Promise.reject(error || 'Could not get current game');
                    });
            } else {
                return res.json();
            }
        })
        .then((game: PuzzleGame) => {
            const date = new Date(game.date);
            const tiles = game.tiles;
            const charValues = new Map<string, number>(Object.entries(game.charValues));
            const solutionChars = game.solutionChars;
            return {
                date,
                tiles,
                charValues,
                solutionChars
            }
        })
}
