import {Constants, WORDS} from "../constants";
import {ITile, Letter, LetterGroup} from "../api";

export enum Direction {
    UP,
    DOWN,
    LEFT,
    RIGHT
}

export abstract class Tile implements ITile {
    id: number
    editable: boolean
    colour: string

    protected constructor(id: number, editable: boolean, colour: string = '#FFFFFF') {
        this.id = id;
        this.editable = editable;
        this.colour = colour;
    }

    static fromPlainObject(tile: ITile): Tile {
        const id = tile.id;
        const colour = tile.colour;
        let instTile;
        if (!tile.editable && tile.startingChar) {
            instTile = new StaticTile(id, tile.startingChar);
        } else if (tile.multiplier && tile.multiplier > 1) {
            instTile = new MultiplierTile(id, tile.multiplier);
        } else {
            instTile = new EditableTile(id);
        }
        if (colour !== undefined) {
            instTile.colour = colour;
        }
        return instTile;
    }

    getLetter(char: string = ''): Letter {
        return {
            char: char,
            numMemberships: 0,
            isValid: false
        }
    }

    private static isRowLastTile = (tileId: number) => (tileId % Constants.Grid.WIDTH) === Constants.Grid.WIDTH - 1;
    private static isRowFirstTile = (tileId: number) => (tileId % Constants.Grid.WIDTH) === 0;

    static isLastInDirection(id: number, direction: Direction, reverse: boolean = false): boolean {
        return Tile.nextIdInDirection(id, direction, reverse) === undefined;
    }

    static nextIdInDirection(fromId: number, direction: Direction, reverse: boolean = false): number | undefined {
        let tileId;
        if ((direction === Direction.UP && !reverse) || (direction === Direction.DOWN && reverse)) {
            tileId = fromId - Constants.Grid.WIDTH;
            if (tileId < 0) {
                return undefined;
            }
        } else if ((direction === Direction.DOWN && !reverse) || (direction === Direction.UP && reverse)) {
            tileId = fromId + Constants.Grid.WIDTH;
            if (tileId >= Constants.Grid.size()) {
                return undefined;
            }
        } else if ((direction === Direction.LEFT && !reverse) || (direction === Direction.RIGHT && reverse)) {
            if (Tile.isRowFirstTile(fromId)) {
                return undefined;
            }
            tileId = fromId - 1;
        } else if ((direction === Direction.RIGHT && !reverse) || (direction === Direction.LEFT && reverse)) {
            if (Tile.isRowLastTile(fromId)) {
                return undefined;
            }
            tileId = fromId + 1;
        } else {
            // Shouldn't happen
            throw new Error();
        }
        return tileId;
    }

    static getRow = (id: number): number => Math.trunc(id / Constants.Grid.WIDTH);

    static getCol = (id: number): number => Math.trunc(id % Constants.Grid.WIDTH);

    static getMirrorTile = (id: number): number => {
        const col = Tile.getCol(id);
        const row = Tile.getRow(id);
        if (col === row) {
            return id;
        }
        return col * Constants.Grid.WIDTH + row;
    }

    abstract getValue(baseValue: number): number;
}

export class EditableTile extends Tile {
    readonly editable: true

    constructor(id: number) {
        super(id, true);
        this.editable = true;
    }

    getValue(baseValue: number): number {
        return baseValue;
    }
}

export class MultiplierTile extends EditableTile {
    readonly multiplier: number

    constructor(id: number, multiplier: number) {
        super(id);
        this.multiplier = multiplier
    }

    getValue(baseValue: number) {
        return super.getValue(baseValue) * this.multiplier
    }
}

export class StaticTile extends Tile {
    readonly startingChar: string
    readonly editable: false

    constructor(id: number, startingChar: string) {
        super(id, false, '#D3D3D3');
        this.startingChar = startingChar
        this.editable = false
    }

    getValue(baseValue: number) {
        return baseValue;
    }

    getLetter(): Letter {
        return {
            char: this.startingChar,
            numMemberships: 0,
            isValid: undefined
        }
    }
}

const isWordValid = (() => {
    const allWords = Object.freeze(new Set(Object.keys(WORDS)));
    return (word: string, validWords = allWords) => word.length >= 1 && validWords.has(word);
})();

const findLetterGroups = (chars: string[], tiles: readonly ITile[], direction: Direction, fromTileId: number, validWords?: Set<string>): {
    validGroups: LetterGroup[],
    invalidGroups: LetterGroup[]
} => {
    const tileIsValid = (tileId?: number): boolean => {
        return tileId !== undefined && chars[tileId] !== '';
    }
    const validGroups: LetterGroup[] = [];
    const invalidGroups: LetterGroup[] = [];
    let currTileId: number | undefined = fromTileId;
    let currGroupIds: number[] = [];
    const addGroup = () => {
        const currGroup = currGroupIds.map((i) => chars[i]).join('');
        const isWord = isWordValid(currGroup, validWords);
        const groups = isWord ? validGroups : invalidGroups;
        // Don't add group as invalid if only members are static tiles
        if (!isWord && currGroupIds.map((i) => tiles[i])
            .every((tile) => tile instanceof StaticTile)) {
            return;
        }
        groups.push({
            group: currGroup,
            memberTileIds: currGroupIds,
            isWord
        })
    }

    while (currTileId !== undefined) {
        if (tileIsValid(currTileId)) {
            currGroupIds.push(currTileId);
        } else {
            if (currGroupIds.length >= Constants.Game.MIN_WORD_LEN) {
                addGroup();
            }
            currGroupIds = [];
        }
        currTileId = Tile.nextIdInDirection(currTileId, direction);
    }
    if (currGroupIds.length >= Constants.Game.MIN_WORD_LEN) {
        addGroup();
        currGroupIds = [];
    }
    return {
        validGroups,
        invalidGroups
    };
}

export function findWords(chars: string[], tiles: readonly ITile[], validWords?: Set<string>): {
    foundValidWords: LetterGroup[],
    foundInvalidWords: LetterGroup[]
} {
    let foundValidWords: LetterGroup[] = [];
    let foundInvalidWords: LetterGroup[] = [];
    for (let col = 0; col < Constants.Grid.WIDTH; col++) {
        const {validGroups, invalidGroups} = findLetterGroups(chars, tiles, Direction.DOWN, col, validWords);
        foundValidWords = [...foundValidWords, ...validGroups];
        foundInvalidWords = [...foundInvalidWords, ...invalidGroups];
    }
    for (let row = 0; row < Constants.Grid.HEIGHT; row++) {
        const {validGroups, invalidGroups} = findLetterGroups(chars, tiles, Direction.RIGHT, row * Constants.Grid.WIDTH, validWords);
        foundValidWords = [...foundValidWords, ...validGroups];
        foundInvalidWords = [...foundInvalidWords, ...invalidGroups];
    }
    return {foundValidWords: foundValidWords, foundInvalidWords: foundInvalidWords};
}