import React, {useMemo} from "react";
import {KeyProps} from "./Key";

interface KeyboardProps {
    onKeyPress: (keyLetter: string) => void
    keyComponent: (props: KeyProps) => JSX.Element
    delKeyComponent?: (props: KeyProps) => JSX.Element
    enabledLetters?: string
}

export default function Keyboard(props: KeyboardProps) {
    const keyboardRowLetters = [
        "qwertyuiop",
        "asdfghjkl",
        "zxcvbnm"
    ]

    const {
        onKeyPress,
        keyComponent: KeyElement,
        delKeyComponent: DelKeyElement,
        enabledLetters
    } = props;

    const keyRows = useMemo(() => {
        const renderKeyLetters = (row: number, keyLetters: string) => {
            const keys = [];
            for (const keyLetter of keyLetters) {
                keys.push(
                    <KeyElement
                        letter={keyLetter}
                        onKeyPress={onKeyPress}
                        key={`row${row}key${keyLetter}`}
                        enabled={enabledLetters === undefined || enabledLetters.includes(keyLetter)}
                    />
                );
            }
            return keys
        }
        const keyRows = [];
        for (let i = 0; i < keyboardRowLetters.length; i++) {
            const keyRow = keyboardRowLetters[i];
            keyRows.push(
                <div key={`row${i}spacer`} style={{
                    flex: 0.2,
                    maxWidth: '1000px',
                }}/>
            )
            keyRows.push(
                <div key={`row${i}`} style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '97%',
                    flex: 1,
                    maxWidth: '500px',
                }}>
                    {renderKeyLetters(i, keyRow)}
                    {(i === keyboardRowLetters.length - 1 && DelKeyElement) && (
                        <DelKeyElement width={1.5} letter='DEL' key={`row${i}keydel`} onKeyPress={() => onKeyPress('')}/>
                    )}
                </div>
            )
        }
        // Keyboard bottom spacer
        keyRows.push(
            <div key={'spacerbottom'} style={{
                flex: 0.5,
                maxWidth: '1000px',
            }}/>
        )
        return keyRows;
    }, [DelKeyElement, keyboardRowLetters]);
    return (
        <>
            {keyRows}
        </>
    )
}