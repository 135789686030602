import {GameSolution, HighScore} from "../common/api";
import {Constants} from "../common/constants";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {getCurrentClassicGame, submitSolution} from "../utils/api";
import GameInfo from "../components/GameInfo";
import Keyboard from "../components/Keyboard";
import GameGrid from "../components/GameGrid";
import {useGridStateReducer} from "../hooks/useGridStateReducer";
import {Key} from "../components/Keyboard/Key";
import {DeleteKey} from "../components/Keyboard/DeleteKey";
import LetterLabel from "../components/LetterLabel/LetterLabel";
import ClassicGameTools from "../components/GameTools/ClassicGameTools";

export default function Classic() {
    const charValuesRef = useRef<Map<string, number>>(new Map<string, number>());
    const [gridState, dispatchGridState] = useGridStateReducer({
        localStorage: window.localStorage,
        localStorageSubPath: 'classic/v1'
    });
    const [highScore, setHighScore] = useState<HighScore>();
    const [showLetterValue, setShowLetterValue] = useState<boolean>(true);
    const updateHighScore = useCallback((callback?: (highScore?: HighScore) => void) => {
        getCurrentClassicGame()
            .then((game) => {
                const highScore = game.highScore;
                if (callback !== undefined) {
                    callback(highScore);
                }
                setHighScore((oldHighScore) => {
                    if (highScore !== oldHighScore) {
                        return highScore;
                    }
                    return oldHighScore;
                });
            })
            .catch((err) => {
                // TODO: Show error popup and/or retry
                console.error(`Could not update current game high score: ${err}`);
            });
    }, []);

    useEffect(() => {
        dispatchGridState({type: 'updatePoints', payload: {values: charValuesRef.current}});
    }, [gridState.letters]);

    useEffect(() => {
        // Register/unregister keydown event listener
        const onKeyDown = (e: KeyboardEvent) => {
            dispatchGridState({type: 'keyDown', payload: {key: e.key}});
        }
        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        }
    }, []);

    // Initialize game
    useEffect(() => {
        getCurrentClassicGame()
            .then((game) => {
                const {date: currDate, tiles: startingTiles, charValues, highScore: currHighScore} = game
                // let hasValidInitialState = false;
                // if (gridState.letters.length > 0) {
                //     hasValidInitialState = true;
                //     // Check if pre-initialized letters have letters from current game's static tiles
                //     for (const startingTile of startingTiles) {
                //         if (!startingTile.editable) {
                //             const i = startingTile.id;
                //             if (gridState.letters[i]?.char !== startingTile.startingChar) {
                //                 hasValidInitialState = false;
                //                 break;
                //             }
                //         }
                //     }
                // }
                // // Instantiate literals as Tiles
                // const instStartingTiles = startingTiles.map(tile => Tile.fromPlainObject(tile));
                // let payload;
                // if (!hasValidInitialState) {
                //     payload = {startingTiles: instStartingTiles};
                // } else {
                //     payload = {startingTiles: instStartingTiles, letters: gridState.letters};
                // }
                dispatchGridState({type: 'initGrid', payload: {startingTiles, date: currDate}});
                charValuesRef.current = charValues;
                setHighScore(currHighScore);
            })
            .catch((err) => {
                // TODO: Show error popup and/or retry
                console.error(`Could not update current game: ${err}`);
            });
    }, []);

    return <>
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flex: 4
        }}>
            <GameInfo points={gridState.invalid ? 0 : gridState.points}
                      date={gridState.date}
                      highScore={highScore}
            />
            <GameGrid
                tiles={gridState.tiles}
                tilesAnimation={gridState.tilesAnimation}
                letters={gridState.letters}
                charValues={charValuesRef.current}
                focusedTile={gridState.focusedTile}
                setFocusedTile={(tileId?: number) => dispatchGridState({
                    type: 'focus',
                    payload: {'focusedTile': tileId}
                })}
                focusDirection={gridState.focusDirection}
                setFocusDirection={() => dispatchGridState({type: 'toggleFocusDirection'})}
                showLetterValue={showLetterValue}
                gameTools={
                    <ClassicGameTools
                        showLetterValue={showLetterValue}
                        toggleShowLetterValue={() => {
                            setShowLetterValue(prevState => !prevState);
                        }}
                        submitGame={() => {
                            // TODO: Replace alerts/prompts with better UI
                            const callback = (currHighScore?: HighScore) => {
                                if (gridState.points <= (currHighScore?.points || 0)) {
                                    alert(`To submit, beat the high score of ${currHighScore?.points || 0} first.\n\nP.S. You got this!`);
                                    return;
                                }
                                let user: string | null = null;
                                const originalMsg = `Congrats! Your score of ${gridState.points} is beating the current high score of ${currHighScore?.points || 0}.\n\nTo submit your score, enter a username (max 12 characters) and press OK:`;
                                let msg = originalMsg;
                                do {
                                    if (user) {
                                        msg = originalMsg + '\nError: username has too many characters! Try again:';
                                    }
                                    user = prompt(msg,
                                        'anonymous');
                                } while (user !== null && user.length > Constants.Player.USER_MAX_LEN);
                                if (user !== null && user.length <= Constants.Player.USER_MAX_LEN) {
                                    const solution: GameSolution = {
                                        letters: gridState.letters.map((letter) => letter.char),
                                    }
                                    if (user && user !== 'anonymous') {
                                        solution.user = user;
                                    }
                                    submitSolution(solution)
                                        .then((res) => {
                                            if (res.isHighScore) {
                                                setHighScore({
                                                    user: user !== null ? user : 'anonymous',
                                                    points: res.score
                                                });
                                            } else {
                                                alert('Sorry, someone submitted a higher score at the same time as you :(');
                                                updateHighScore();
                                            }
                                        })
                                        .catch((err) => {
                                            alert('Error: Something went wrong, try again');
                                            console.error(err);
                                        });
                                }
                            }
                            updateHighScore(callback);
                        }}
                        resetGrid={() => {
                            const res = window.confirm(
                                'Are you sure you want to reset your grid?\nYou will lose ALL of your current progress.')
                            if (res) {
                                dispatchGridState({type: 'clearLocalStorage'});
                                window.location.reload();
                            }
                        }}
                    />
                }
            />
        </div>
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flex: 1.5
        }}>
            <Keyboard
                onKeyPress={(keyChar: string) => {
                    dispatchGridState({
                        type: 'typeChar', payload: {char: keyChar}
                    });
                }}
                keyComponent={(props) => (<Key {...props}>
                    <LetterLabel value={charValuesRef.current.get(props.letter) || 0}/>{props.letter.toUpperCase()}
                </Key>)}
                delKeyComponent={DeleteKey}
            />
        </div>
    </>;
}