export interface ITile {
    id: number,
    editable: boolean
    multiplier?: number
    startingChar?: string
    colour?: string
}

export interface HighScore {
    points: number,
    user?: string
}

export interface Game {
    date: Date,
    tiles: ITile[]
    charValues: Map<string, number>
    highScore?: HighScore
}

export interface PuzzleGame extends Game {
    solutionChars: string[]
}

export enum GameMode {
    Classic = 'CLASSIC',
    Puzzle = 'PUZZLE'
}

export interface GameSolution {
    letters: string[]
    user?: string
}

export type GameSolutionResponse = {
    score: number
    isHighScore: boolean
    error?: string
}

export interface Letter {
    char: string
    numMemberships: number
    isValid?: boolean
}

export interface LetterGroup {
    group: string
    memberTileIds: number[]
    isWord: boolean
}