import {Direction, Tile} from "../../common/utils";
import {Letter} from "../../common/api";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {Constants} from "../../common/constants";
import GameTile from "./GameTile";
import './styles.css';
import Spinner from "../Spinner";

// interface ViewPortDimensions {
//     height: number
//     width: number
// }

interface GameGridProps {
    tiles: Tile[]
    tilesAnimation: boolean[]
    letters: Letter[]
    focusedTile?: number
    setFocusedTile: (tileId?: number) => void
    focusDirection: Direction.RIGHT | Direction.DOWN
    setFocusDirection: () => void
    hideChars?: boolean
    gameTools?: React.ReactNode
    charValues?: Map<string, number>
    showLetterValue?: boolean
}

function debounce<Params extends any[]>(
    func: (...args: Params) => any,
    timeout: number,
    leadingFunc?: () => any
): (...args: Params) => void {
    let timer: NodeJS.Timeout | undefined;
    return (...args: Params) => {
        if (!timer && leadingFunc) {
            leadingFunc();
        }
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            func(...args)
            timer = undefined;
        }, timeout)
    }
}

export default function GameGrid(props: GameGridProps) {
    const {
        tiles,
        tilesAnimation,
        letters,
        focusedTile,
        setFocusedTile,
        focusDirection,
        setFocusDirection,
        gameTools = null,
        charValues = new Map<string, number>(),
        showLetterValue = true,
        hideChars = false
    } = props;
    //const containerRef = useRef<HTMLDivElement>(null);
    //const MAX_SIDE_LENGTH_PERCENT = 0.95
    //const [sideLength, setSideLength] = useState<number>(0);
    // const [showLetterValue, setShowLetterValue] = useState<boolean>(true);
    // const [viewPortDims, setViewPortDims] = useState<ViewPortDimensions>({
    //     height: document.documentElement.clientHeight, width: document.documentElement.clientWidth
    // });

    // const updateLength = useMemo(() => (
    //     debounce(
    //         (width: number, height: number) => {
    //             setSideLength(Math.floor(Math.min(width, height) * MAX_SIDE_LENGTH_PERCENT));
    //         },
    //         500,
    //         () => {
    //             setSideLength(0)
    //         })
    // ), []);

    // useEffect(() => {
    //     updateLength(containerRef?.current?.offsetWidth || 0, containerRef?.current?.offsetHeight || 0);
    // }, [updateLength, viewPortDims]);

    // Register/unregister keydown event listener
    // useEffect(() => {
    //     const onResize = () => {
    //         setViewPortDims({
    //             height: document.documentElement.clientHeight,
    //             width: document.documentElement.clientWidth
    //         });
    //     }
    //     window.addEventListener('resize', onResize);
    //     window.addEventListener('orientationchange', onResize);
    //
    //     // Unregister
    //     return () => {
    //         window.removeEventListener('resize', onResize);
    //         window.removeEventListener('orientationchange', onResize);
    //     }
    // }, []);

    // const getGridSideLength = () => {
    //     return Math.floor(
    //         Math.min(containerRef.current?.clientWidth || 0, containerRef.current?.clientHeight || 0)
    //         * MAX_SIDE_LENGTH_PERCENT);
    // }
    const renderTiles = () => [...Array(Constants.Grid.size()).keys()].map((_, tileId) => {
        const hasFocus = focusedTile === tileId;
        const tile = tiles[tileId];
        const letter = letters[tileId]
        const letterValue = charValues?.get(letter?.char) || 0;
        const tileValue = tile?.getValue(letterValue * letter.numMemberships) || 0;
        return <GameTile
            key={tileId}
            tile={tile}
            letter={letters[tileId]}
            letterValue={letterValue}
            tileValue={tileValue}
            hasFocus={hasFocus}
            setFocus={() => setFocusedTile(tileId)}
            onClick={setFocusDirection}
            focusDirection={hasFocus ? focusDirection : undefined}
            gridDimension={0}
            showLetterValue={showLetterValue}
            colour={tile.colour}
            animate={tilesAnimation[tileId]}
            hideChar={hideChars}
        />
    })

    if (tiles.length === 0) {
        return <Spinner/>
    }
    return (
        <>
            <div style={{
                maxWidth: '40vh',
                width: '100%',
                flex: 0.1,
                marginBottom: '8px'
            }}>
                {gameTools}
            </div>
            <div style={{
                display: 'flex',
                flex: 1,
                width: '100%',
                maxWidth: '40vh',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div
                    className={"square-container"}
                    // style={{
                    //     display: 'grid',
                    //     gridTemplateColumns: `repeat(${Constants.Grid.WIDTH}, 1fr)`,
                    //     outline: `0.2vh solid black`,
                    // }}
                >
                    {tiles.length > 0 && renderTiles()}
                </div>
            </div>
        </>
    );
}