import React, {useCallback, useEffect, useState} from "react";
import {getCurrentPuzzleGame} from "../utils/api";
import GameInfo from "../components/GameInfo";
import Keyboard from "../components/Keyboard";
import GameGrid from "../components/GameGrid";
import {Key} from "../components/Keyboard/Key";
import {MultiplierLetterLabel} from "../components/LetterLabel/MultiplierLetterLabel";
import usePuzzleGridStateReducer from "../hooks/usePuzzleGridStateReducer";
import {DeleteKey} from "../components/Keyboard/DeleteKey";
import {Dialog} from "../components/Dialog";
import {useNavigate} from "react-router";
import {formatTimeElapsed, getPuzzleShareText} from "../utils/strings";
import ShareDialog from "../components/Dialog/ShareDialog";
import {Tile} from "../common";
import {lessThanOneDayElapsed} from "../utils/dates";
import PuzzleGameTools from "../components/GameTools/PuzzleGameTools";

export default function Puzzle() {
    const [gridState, dispatchGridState] = usePuzzleGridStateReducer();
    const [allowedLetters, setAllowedLetters] = useState<string>('');
    const [showSolveAlert, setShowSolveAlert] = useState<boolean>(false);
    const [startingWord, setStartingWord] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        if (gridState && gridState.date && !lessThanOneDayElapsed(gridState.date)) {
            window.location.reload()
        }
    })

    useEffect(() => {
        // Register/unregister keydown event listener
        const onKeyDown = (e: KeyboardEvent) => {
            dispatchGridState({type: 'keyDown', payload: {key: e.key}});
        }
        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        }
    }, []);

    // Initialize game
    useEffect(() => {
        getCurrentPuzzleGame()
            .then((game) => {
                const {date: currDate, tiles: startingTiles, charValues, solutionChars} = game;
                const ignoredIds = new Set();
                const startingChars = [];
                startingTiles.sort((a, b) => a.id - b.id)
                for (const tile of startingTiles) {
                    if (!tile.editable && !ignoredIds.has(tile.id)) {
                        startingChars.push(tile.startingChar);
                        ignoredIds.add(Tile.getMirrorTile(tile.id))
                    }
                }
                setStartingWord(startingChars.join(''));
                dispatchGridState({
                    type: 'initGrid',
                    payload: {
                        startingTiles,
                        date: currDate,
                        startingCharsRemaining: charValues,
                        solutionChars: solutionChars
                    }
                });
                const allowedLetters = Array.from(charValues.keys())
                    .filter((char) => (charValues.get(char) || 0) > 0)
                    .join('');
                setAllowedLetters(allowedLetters);
            })
            .catch((err) => {
                // TODO: Show error popup and/or retry
                console.error(`Could not update current game: ${err}`);
            });
    }, []);

    useEffect(() => {
        setShowSolveAlert(gridState.isSolved);
    }, [gridState.isSolved]);

    const key = useCallback((props) => (<Key {...props}>
        {props.enabled &&
            <MultiplierLetterLabel multiplier={gridState.charsRemaining.get(props.letter) || 0}
                                   fontSize={'12px'}/>}
        {props.enabled && props.letter.toUpperCase()}
    </Key>), [gridState.charsRemaining])
    if (gridState.tiles.length > 0 && gridState.startTime === undefined) {
        return <Dialog header={'Ready?'} body={"The timer will begin as soon as you click 'Start!'"}
                       confirmText={"Start!"}
                       cancelText={"Main Menu"}
                       onConfirm={() => dispatchGridState({type: 'startPuzzle'})}
                       onCancel={() => navigate('/')}
        />
    }
    return <>
        {showSolveAlert && <ShareDialog header={'Congratulations!'}
                                        body={`Solve Time: ${formatTimeElapsed(gridState.solveTime || 0)}`}
                                        shareText={getPuzzleShareText(gridState.date, gridState.solveTime || 0, gridState.solveOrder, startingWord)}
                                        onConfirm={() => setShowSolveAlert(false)}/>}
        {gridState.pauseTime !== undefined && <Dialog header={'Game Paused'}
                                                      body={"Feel free to leave this page,\nyour current time will be saved!"}
                                                      confirmText={'Resume Game'}
                                                      onConfirm={() => dispatchGridState({type: 'resumePuzzle'})}/>}
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flex: 4
        }}>
            <GameInfo date={gridState.date}
                      solveTime={gridState.solveTime}
                      startTime={gridState.startTime}
                      pauseTime={gridState.pauseTime}
            />
            <GameGrid
                tiles={gridState.tiles}
                tilesAnimation={gridState.tilesAnimation}
                letters={gridState.letters}
                focusedTile={gridState.focusedTile}
                setFocusedTile={(tileId?: number) => dispatchGridState({
                    type: 'focus',
                    payload: {'focusedTile': tileId}
                })}
                focusDirection={gridState.focusDirection}
                setFocusDirection={() => dispatchGridState({type: 'toggleFocusDirection'})}
                hideChars={gridState.pauseTime !== undefined}
                gameTools={
                    <PuzzleGameTools
                        resetGrid={() => {
                            const res = window.confirm(
                                'Are you sure you want to reset your grid?\nYou will lose ALL of your current progress.')
                            if (res) {
                                dispatchGridState({
                                    type: 'initGrid',
                                    payload: {startingTiles: [], startingCharsRemaining: new Map(), solutionChars: []}
                                });
                                window.location.reload();
                            }
                        }}
                        isTimerPaused={gridState.pauseTime !== undefined}
                        togglePauseTimer={() => {
                            if (gridState.pauseTime !== undefined) {
                                dispatchGridState({type: 'resumePuzzle'});
                            } else {
                                dispatchGridState({type: 'pausePuzzle'});
                            }
                        }}
                    />
                }
            />
        </div>
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flex: 1.5
        }}>
            <Keyboard
                onKeyPress={(keyChar: string) => {
                    dispatchGridState({
                        type: 'typeChar', payload: {char: keyChar}
                    });
                }}
                enabledLetters={gridState.pauseTime !== undefined ? "" : allowedLetters}
                keyComponent={key}
                delKeyComponent={DeleteKey}
            />
        </div>
    </>;
}