import {Constants} from "../common/constants"

export function formatTimeElapsed(timeElapsed: number) {
    const totalSeconds = Math.floor(timeElapsed / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${String(seconds).padStart(2, '0')}`;
}

export function getPuzzleShareText(date: Date, solveTime: number, solveOrder: number[], startingWord: string) {
    let solveGrid: string[] = [];
    for (let i = solveOrder.length - 1; i >= 0; i--) {
        for (let j = 0; j < Constants.Grid.WIDTH; j++) {
            solveGrid[solveOrder[i] * Constants.Grid.WIDTH + j] = i > 0 ? String(i) : startingWord[j];
        }
        for (let j = 0; j < Constants.Grid.HEIGHT; j++) {
            solveGrid[j * Constants.Grid.WIDTH + solveOrder[i]] = i > 0 ? String(i) : startingWord[j];
        }
    }
    let solveGridRowText: string[] = [];
    for (let i = 0; i < Constants.Grid.WIDTH; i++) {
        solveGridRowText.push(solveGrid.slice(i * Constants.Grid.WIDTH, i * Constants.Grid.WIDTH + Constants.Grid.WIDTH).join(' '))
    }
    let solveGridText = solveGridRowText.join('\n')
    return `SQUARY (Puzzle Mode)
${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}
Time: ${formatTimeElapsed(solveTime)}
Order:

${solveGridText}`;
}