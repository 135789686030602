import {Link} from "react-router-dom";

export default function Error() {
    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        gap: '10px'
    }}>
        <div style={{fontSize: '48px', fontWeight: 'bold'}}>:(</div>
        <div style={{fontSize: '32px'}}>Oops, there was an error.</div>
        <div>Click <Link to={'/'}>here</Link> to return to the main menu.</div>
    </div>
}
