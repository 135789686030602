import React, {PropsWithChildren} from "react";
import './styles.css';

export interface KeyProps {
    letter: string
    onKeyPress: (keyLetter: string) => void
    width?: number
    enabled?: boolean
}

export function Key(props: PropsWithChildren<KeyProps>) {
    const {
        letter,
        onKeyPress,
        width = 1,
        enabled = true,
        children
    } = props;

    return (
        <button
            className={'key'}
            onClick={() => onKeyPress(letter)}
            disabled={!enabled}
            style={{
                width: `${9 * width}%`,
            }}
        >
            {children}
        </button>
    );
}